<template>
  <div>
    <h3>Participations</h3>
    <p>Find below all participation of Robert Los Commercial and Contract Services</p>
    <div class="table-container">
      <table class="styled-table" v-if="participants.length">
        <thead>
        <tr>
          <th>Participation Name</th>
          <th>From Date</th>
          <th>To Date</th>
          <th>Description</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <!-- Iterate over participants and render each row -->
        <tr v-for="participant in participants" :key="participant.id">
          <td>{{ participant.name }}</td>
          <td>{{ formatDate(participant.from_date) }}</td>
          <td>{{ participant.to_date ? formatDate(participant.to_date) : "Ongoing" }}</td>
          <td>{{ participant.description }}</td>
          <td :class="{ active: participant.status === 'active', inactive: participant.status === 'inactive' }">
            {{ participant.status }}
          </td>
        </tr>
        </tbody>
      </table>
      <p v-else>No participation data available.</p>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      participants: [], // Stores fetched participation data
    };
  },
  methods: {
    async fetchParticipants() {
      try {
        // Fetching participation data from the backend endpoint
        const backendUrl = process.env.VUE_APP_BACKEND_URL;
        const response = await fetch("http://localhost:8993/participations/api/v1?format=json");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.participants = data; // Assign the fetched data
      } catch (error) {
        console.error("Failed to fetch participants:", error);
      }
    },
    formatDate(date) {
      // Formats date strings into a more readable format
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  },
  mounted() {
    // Fetching data as soon as the component mounts
    this.fetchParticipants();
  },
};

</script>

<style>
/* Flexbox container to center the table */
.table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0; /* Remove default margin */
  padding: 10px 0; /* Adjust padding as needed */

  background-color: #f8f9fa; /* Optional subtle background color */
}

/* Styled table */
.styled-table {
  border-collapse: collapse;
  font-size: 16px;
  width: 80%; /* Adjust width as needed */
  max-width: 800px; /* Optional to limit table width */
  margin: 20px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for aesthetics */
  background-color: #ffffff; /* Background color */
}

/* Table header styling */
.styled-table thead tr {
  background-color: #007bff; /* Header background (blue) */
  color: #ffffff; /* Header text color */
  text-align: left;
}

/* Table cell styles */
.styled-table th,
.styled-table td {
  padding: 12px 15px; /* Add padding to cells */
  border: 1px solid #dddddd; /* Light border around cells */
}

/* Table row hover effect */
.styled-table tbody tr:hover {
  background-color: #f1f1f1; /* Light gray on hover */
}

/* Optional: Style even rows differently */
.styled-table tbody tr:nth-of-type(even) {
  background-color: #f9f9f9; /* Light background for even rows */
}

/* Responsive table for smaller screens */
@media screen and (max-width: 600px) {
  .styled-table {
    font-size: 14px;
    width: 100%; /* Expand table to fit smaller screens */
  }

  .styled-table th,
  .styled-table td {
    padding: 10px; /* Reduce cell padding for smaller screens */
  }
}

.left {
  text-align: left;
}
</style>
